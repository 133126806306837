import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useModal } from "../../../../provider";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";

import {
    SESSION_TYPE_EQUIPMENT_RUN,
    SESSION_TYPE_BODY_WEIGHT,
    SESSION_TYPE_CIRCUIT,
    SESSION_TYPE_EQUIPMENT,
    SESSION_TYPE_STRENGTH_STABILITY,
    SESSION_TYPE_PILATES,
    SESSION_TYPE_BOXING,
    SESSION_TYPE_SAND_SESSION,
    SESSION_TYPE_SATURDAY_SESSION,
    SESSION_TYPE_MOVING_CIRCUIT,
    SESSION_TYPE_LUNCH,
    SESSION_TYPE_YBELL,
    SESSION_TYPE_ABS_BW_YBELL,
    SESSION_TYPE_MUMS_AND_BUBS,
    SESSION_TYPE_TEENS,
    SESSION_TYPE_PERSONAL_TRAINING,
    SESSION_TYPE_LONG_RUN,
    SESSION_TYPE_ONLINE_BOXING,
    SESSION_TYPE_RESTORATIVE_PILATES,
    SESSION_TYPE_ONLINE_PILATES,
    SESSION_TYPE_EQUIPMENT_OR_LONG_RUN,
    SESSION_TYPE_ONLINE,
    SESSION_TYPE_RESISTANCE_METCON,
    SESSION_TYPE_RESISTANCE_STRENGTH,
    SESSION_TYPE_STRENGTH_ENDURANCE,
    SESSION_TYPE_STRENGTH_STABILITY_OR_LONG_RUN,
} from "../../../constants";

const equipmentImage = "/img/sessions/Equipment.jpg";
const bodyWeightImage = "/img/sessions/BodyWeight.jpg";
const boxingImage = "/img/sessions/Boxing.jpg";
const strengthStabilityImage = "/img/sessions/StrengthAndStability.jpg";
const movingCircuitImage = "/img/sessions/MovingCircuit.jpg";
const sandSessionImage = "/img/sessions/SandSession.jpg";
const defaultImage = equipmentImage;

export default function WorkoutModal() {
    const modal = useModal();
    var workout;
    if (
        typeof modal.workoutModalData === "string" ||
        modal.workoutModalData instanceof String
    ) {
        workout = getWorkoutData(modal.workoutModalData);
    } else {
        workout = modal.workoutModalData;
    }
    // {name, description, image}
    return (
        <Transition.Root show={modal.workoutModalOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed z-50 inset-0 overflow-y-auto"
                onClose={() => {
                    modal.setWorkoutModalOpen(false);
                }}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="relative">
                                {modal.workoutModalData.image ? (
                                    <GatsbyImage
                                        className="absolute-important inset-0 w-full object-center object-cover z-0 min-h-1/3 h-60"
                                        image={getImage(
                                            modal.workoutModalData.image
                                        )}
                                        alt={workout.name}
                                    />
                                ) : (
                                    <img
                                        src={workout.image || defaultImage}
                                        alt={workout.name}
                                        loading="lazy"
                                        className="absolute inset-0 w-full object-center object-cover z-0 min-h-1/3 h-60"
                                    />
                                )}
                                <div className="absolute inset-0 bg-gradient-to-t from-gray-400 to-transparent mix-blend-multiply" />
                                <div className="flex align-bottom items-end h-60 px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:align-middle sm:max-w-xl sm:w-full sm:p-6 ">
                                    <div className="block absolute top-0 right-0 pt-4 pr-4 z-40">
                                        <button
                                            type="button"
                                            className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-feat-lightgrey"
                                            onClick={() =>
                                                modal.setWorkoutModalOpen(false)
                                            }
                                        >
                                            <span className="sr-only">
                                                Close
                                            </span>
                                            <XIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                    <div className="">
                                        <h3 className="text-heading uppercase text-white text-3xl z-40">
                                            {workout.name}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="p-6 text-left">
                                <p>{workout.description}</p>
                            </div>
                            {/* <FreeTrialForm
                afterSubmit={() => modal.setWorkoutModalOpen(false)}
                selectedLocationSlug={modal.selectedLocationSlug}
              /> */}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

const generateSession = (title, description, image) => ({
    name: title,
    description,
    image,
});

const runContent =
    "Running with the FEAT Run Club is not like any running you have ever done before. Run club is an organised running where you get to smash out YOUR distance with a great bunch of people. Everyone can get through a Run Club from people who have not run more than 5km to people training for Half, Full and Ultra Marathons. Be prepared, there is a big focus on running technique and socialising as you run!";
const run = generateSession("Run Club", runContent);

const movingCircuitContent =
    "A moving circuit uses natural obstacles for a combination of cardio and strength sessions. It is a true FEAT session as we explore the park and play in the natural surroundings";
const movingCircuit = generateSession(
    "Moving Circuit",
    movingCircuitContent,
    movingCircuitImage
);

const circuitContent =
    "FEAT Circuit training is an amazing way to not only build your strength and endurance, but to also feel like you’ve had a workout. Cycling through a variety of exercises with minimal rest, it’s a great way to push yourself no matter what fitness level you are at.;";
const circuit = generateSession("Circuit", circuitContent, bodyWeightImage);

const strengthStabilityContent =
    "Our strength, movement & stability sessions are essential for individuals of all fitness levels as it focuses on the correct activation of local stabilizing muscles including our deep core muscles and glutes, making it effective for injury prevention. Exercises are completed in a controlled, precise manner to ensure good technique, effectively retraining motor patterns. One of the best aspects of our strength, movement & stability sessions is that it is adaptable and can be made appropriate for a range of fitness levels from the beginner to the elite athlete. Finally, strength, movement & stability is a form of exercise where you should feel like you’ve worked each part of your body but still leave feeling refreshed, rejuvenated and ready for the rest of the day.";
const strengthStability = generateSession(
    "Strength, Movement & Stability",
    strengthStabilityContent,
    strengthStabilityImage
);

const strengthStabilityOrRunContent = `Running with the FEAT Run Club is not like any running you have ever done before. Run Club is an organised running where you get to smash out YOUR distance with a great bunch of people. Everyone can get through a Run Club from people who have not run more than 5km to people training for Half, Full and Ultra Marathons. Be prepared, there is a big focus on running technique and socialising as you run!

Our strength, movement & stability sessions are essential for individuals of all fitness levels as it focuses on the correct activation of local stabilizing muscles including our deep core muscles and glutes, making it effective for injury prevention. Exercises are completed in a controlled, precise manner to ensure good technique, effectively retraining motor patterns. One of the best aspects of our strength, movement & stability sessions is that it is adaptable and can be made appropriate for a range of fitness levels from the beginner to the elite athlete. Finally, strength, movement & stability is a form of exercise where you should feel like you’ve worked each part of your body but still leave feeling refreshed, rejuvenated and ready for the rest of the day.
  `;
const strengthStabilityOrRun = generateSession(
    "Long Run or Strength, Movement & Stability",
    strengthStabilityOrRunContent,
    strengthStabilityImage
);

const pilatesContent =
    "Pilates is essential for individuals of all fitness levels as it focuses on the correct activation of local stabilizing muscles including our deep core muscles and glutes, making it effective for injury prevention. Exercises are completed in a controlled, precise manner to ensure good technique, effectively retraining motor patterns. One of the best aspects of Pilates is that it is adaptable and can be made appropriate for a range of fitness levels from the beginner to the elite athlete. Finally, Pilates is a form of exercise where you should feel like you’ve worked each part of your body but still leave feeling refreshed, rejuvenated and ready for the rest of the day.";
const pilates = generateSession(
    "Pilates",
    pilatesContent,
    strengthStabilityImage
);

const equipmentContent =
    "These equipment sessions are a foundational part of our FEAT Training system. Integrating the whole community into these dynamic training sessions, you can expect the unexpected. Never doing 2 sessions the same, the training variety in our program is born and bread from these equipment sessions.";
const equipment = generateSession(
    "Equipment",
    equipmentContent,
    equipmentImage
);

const equipmentOrRunContent = `These equipment sessions are a foundational part of our FEAT Training system. Integrating the whole community into these dynamic training sessions, you can expect the unexpected. Never doing 2 sessions the same, the training variety in our program is born and bread from these equipment sessions.
  
  Running with the FEAT Run Club is not like any running you have ever done before. Run Club is an organised running where you get to smash out YOUR distance with a great bunch of people. Everyone can get through a Run Club from people who have not run more than 5km to people training for Half, Full and Ultra Marathons. Be prepared, there is a big focus on running technique and socialising as you run!
  `;
const equipmentOrRun = generateSession(
    "Equipment or Long Run",
    equipmentOrRunContent,
    equipmentImage
);

const bodyWeightContent =
    "This is bodyweight training, but is far more than you know it to be. There’s minimal equipment with maximal movement. Sessions often start with group play, followed by plenty of functional movement patterns and team workouts that result in greater joint range of motion and plenty of connecting to your training community.";
const bodyWeight = generateSession(
    "Body Weight",
    bodyWeightContent,
    bodyWeightImage
);

const boxingContent =
    "Always one of the most popular sessions in a training week. A FEAT boxing session does not only give you the opportunity to punch away any stresses with a muscle burning and lung busting boxing session. But it is also always a Fun session with plenty of laughs.";
const boxing = generateSession("Boxing", boxingContent, boxingImage);

const onlineBoxingContent =
    "Always one of the most popular sessions in a training week. A FEAT boxing session does not only give you the opportunity to punch away any stresses with a muscle burning and lung busting boxing session. But it is also always a Fun session even online, we get you moving and feeling this session.";
const onlineBoxing = generateSession("Boxing", onlineBoxingContent);

const equipmentRunContent =
    "These are some of our flagship sessions, getting high quality strength & equipment based training, mixed in with a dynamic running set. The combination is designed to slingshot your running and strength, by giving you the choice to dictate what you want to get out of the training. It also gives you a chance to train with different people in your training group and different FEAT trainers.";
const equipmentRun = generateSession(
    "Equipment + Run",
    equipmentRunContent,
    equipmentImage
);

const sandSessionContent =
    "These sand sessions are the perfect combination of hard work and FUN! Getting down on the sand adds another element to your training and does a great job on bringing that heart rate up, but there is so much FUN to be had on the sand which is FEAT in a nutshell!";
const sandSession = generateSession(
    "Sand Session",
    sandSessionContent,
    sandSessionImage
);

const lunchContent =
    "All the components of our famous FEAT training sessions, specifically designed to re-energise you for the rest of the work day. There is a mix of equipment each week to provide variety and avoid your body getting used to the same movements day in and day out, as well as integrating mobility work so you get back to work feeling better than when you left.";
const lunch = generateSession(
    "FEAT Lunch-time Blast",
    lunchContent,
    movingCircuitImage
);

const saturdayContent =
    "We call Saturday’s our miscellaneous session, meaning you never know what you’re going to get. It could be anything from a body weight blast, equipment you haven't seen that week, boxing or anything else. Really the Saturday Session is our gateway to Coffee Club, with people staying around every Saturday to catch up, have a coffee and even brekkie together. Definitely, full Saturday vibes";
const saturday = generateSession(
    "Saturday Sessions",
    saturdayContent,
    bodyWeightImage
);

const ybellContent =
    "The newest training tool on the market, these YBell session are an integral part of our Bondi Beach and Rushcutters Bay Evening FEAT Training system. " +
    "Integrating the whole community into these dynamic training sessions, you can expect the unexpected. " +
    "Never doing 2 sessions the same, the training variety in our program is born and bred from these sessions.";
const ybell = generateSession("YBell Session", ybellContent);

const absBwYbellContent =
    "As the name suggests, there is alot that goes into these Abs/Core, Body Weight and YBell Mobility sessions. " +
    "Using your body weight and the YBell, get strong in all ranges with the specific target on truck stability (abs/core). " +
    `These workouts result in greater joint range of motion and plenty of connecting to your training community, at FEAT we don't forget to have fun.`;

const absBwYbell = generateSession(
    "Abs/Core, Body Weight and YBell Mobility",
    absBwYbellContent
);

const mumsAndBubsContent =
    "Mums & Bubs sessions are designed to suit our busy mums and work with you. " +
    `Each session is designed for a 30 minute workout, but feel free to arrive later if needed, we'll be running the class for 90 minutes and you can drop in when it suits you best that day.`;

const mumsAndBubs = generateSession(
    "Mums & Bubs - Flexible Sessions",
    mumsAndBubsContent
);

const teensContent =
    `Our Teens program runs during the school term. All teens between the ages of 12-16 years old (or in school from years 7 to 10) are welcome. ` +
    `Our overall aims with these sessions is clear - to give teens a space to be guided through their training, supported in their individual training goals and provide a place to have fun!`;

const teens = generateSession("Teens Program", teensContent);

const ptContent =
    `Our Trainers are also available to take one-on-one personal training sessions. These are a great opportunity if you are looking for some specialised options to your training,` +
    ` or even if our group session time slots don't fit your schedule. To get started with personal training, enquire below and we'll be in touch shortly to get you started.`;
const pt = generateSession("Personal Training", ptContent);

const onlineContent = `Our online program is an interactive session with a whole range of body weight and resistence training. We'll incorporate any equipment you may have in the session and provide a full body workout.`;
const online = generateSession("FEAT From Home - Online", onlineContent);

const resistanceMetconContent = `Metcon (Metabolic Conditioning) - where we will be using a mix of resistance training and bodyweight work to get through interval and circuit style sessions.`;
const resistanceMetcon = generateSession(
    "Resistance - Metcon",
    resistanceMetconContent
);

const resistanceStrengthContent = `A strength-based session is always focusing on how long the targeted muscle group will stay under tension for. In true FEAT style, this will include a huge variety of loading and will not be one style, but guarantee you'll walk away feeling stronger for it!`;
const resistanceStrength = generateSession(
    "Resistance - Strength",
    resistanceStrengthContent
);

const strengthEnduranceContent = `Strength endurance training is about sustaining power & force over longer periods of time. To put it simply, we are not testing maximal strength, so as we add more volume we go further down towards the endurance zone, yet not letting it become aerobic training. Think load, reps and fun!`;
const strengthEndurance = generateSession(
    "Strength Endurance",
    strengthEnduranceContent
);

const getWorkoutData = (session_name) => {
    switch (session_name) {
        case SESSION_TYPE_BOXING:
            return boxing;
        case SESSION_TYPE_EQUIPMENT_RUN:
            return equipmentRun;
        case SESSION_TYPE_BODY_WEIGHT:
            return bodyWeight;
        case SESSION_TYPE_EQUIPMENT:
            return equipment;
        case SESSION_TYPE_STRENGTH_STABILITY:
            return strengthStability;
        case SESSION_TYPE_STRENGTH_STABILITY_OR_LONG_RUN:
            return strengthStabilityOrRun;
        case SESSION_TYPE_PILATES:
            return pilates;
        case SESSION_TYPE_SAND_SESSION:
            return sandSession;
        case SESSION_TYPE_CIRCUIT:
            return circuit;
        case SESSION_TYPE_MOVING_CIRCUIT:
            return movingCircuit;
        case SESSION_TYPE_SATURDAY_SESSION:
            return saturday;
        case SESSION_TYPE_LUNCH:
            return lunch;
        case SESSION_TYPE_YBELL:
            return ybell;
        case SESSION_TYPE_ABS_BW_YBELL:
            return absBwYbell;
        case SESSION_TYPE_MUMS_AND_BUBS:
            return mumsAndBubs;
        case SESSION_TYPE_TEENS:
            return teens;
        case SESSION_TYPE_PERSONAL_TRAINING:
            return pt;

        case SESSION_TYPE_LONG_RUN:
            return run;
        case SESSION_TYPE_ONLINE_BOXING:
            return onlineBoxing;
        case SESSION_TYPE_RESTORATIVE_PILATES:
            return pilates;
        case SESSION_TYPE_ONLINE_PILATES:
            return pilates;
        case SESSION_TYPE_EQUIPMENT_OR_LONG_RUN:
            return equipmentOrRun;
        case SESSION_TYPE_ONLINE:
            return online;
        case SESSION_TYPE_RESISTANCE_METCON:
            return resistanceMetcon;
        case SESSION_TYPE_RESISTANCE_STRENGTH:
            return resistanceStrength;
        case SESSION_TYPE_STRENGTH_ENDURANCE:
            return strengthEndurance;
        default:
            return `Session Description Missing. But it'll be really good!`;
    }
};
