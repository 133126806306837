export const SESSION_TYPE_EQUIPMENT_RUN = "equipment_run";
export const SESSION_TYPE_BODY_WEIGHT = "body_weight";
export const SESSION_TYPE_CIRCUIT = "circuit";
export const SESSION_TYPE_EQUIPMENT = "equipment";
export const SESSION_TYPE_STRENGTH_STABILITY = "strength_stability";
export const SESSION_TYPE_STRENGTH_STABILITY_OR_LONG_RUN =
    "strength_stability_long_run";
export const SESSION_TYPE_PILATES = "pilates";
export const SESSION_TYPE_PILATES_OR_LONG_RUN = "pilates_long_run";
export const SESSION_TYPE_EQUIPMENT_OR_PILATES = "equipment_pilates";
export const SESSION_TYPE_BOXING = "boxing";
export const SESSION_TYPE_BOXING_OR_LONG_RUN = "boxing_long_run";
export const SESSION_TYPE_SAND_SESSION = "sand_session";
export const SESSION_TYPE_SATURDAY_SESSION = "saturday";
export const SESSION_TYPE_MOVING_CIRCUIT = "moving_circuit";
export const SESSION_TYPE_MOVING_CIRCUIT_BODY_WEIGHT =
    "moving_circuit_body_weight";
export const SESSION_TYPE_MOVING_CIRCUIT_RUN = "moving_circuit_run";
export const SESSION_TYPE_MOVING_CIRCUIT_YOGA = "moving_circuit_yoga";
export const SESSION_TYPE_YOGA = "yoga";
export const SESSION_TYPE_LUNCH = "lunch";
export const SESSION_TYPE_YBELL = "ybell";
export const SESSION_TYPE_ABS_BW_YBELL = "abs-bw-ybell";
export const SESSION_TYPE_MUMS_AND_BUBS = "mums-and-bubs";
export const SESSION_TYPE_TEENS = "teens";
export const SESSION_TYPE_PERSONAL_TRAINING = "pt_session";

export const SESSION_TYPE_ONLINE_BOXING = "online_boxing";
export const SESSION_TYPE_BODY_WEIGHT_OR_ONLINE_BOXING =
    "body_weight_or_online_boxing";
export const SESSION_TYPE_LONG_RUN = "long_run";
export const SESSION_TYPE_RESTORATIVE_PILATES = "restorative_pilates";
export const SESSION_TYPE_ONLINE_PILATES = "online_pilates";
export const SESSION_TYPE_EQUIPMENT_OR_LONG_RUN = "equipment_long_run";
export const SESSION_TYPE_ONLINE = "online";
export const SESSION_TYPE_RESISTANCE_METCON = "resistance_metcon";
export const SESSION_TYPE_RESISTANCE_STRENGTH = "resistance_strength";
export const SESSION_TYPE_STRENGTH_ENDURANCE = "strength_endurance";
